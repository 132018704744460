/**
 * @description i18n en
 * @author wangfupeng
 */

export default {
  highLightModule: {
    selectLang: 'Language',
  },
}
