/**
 * @description i18n zh-CN
 * @author wangfupeng
 */

export default {
  uploadImgModule: {
    uploadImage: '上传图片',
    uploadError: '{{fileName}} 上传出错',
  },
}
