/**
 * @description header5 button menu
 * @author wangfupeng
 */

import HeaderButtonMenuBase from './HeaderButtonMenuBase'

class Header5ButtonMenu extends HeaderButtonMenuBase {
  title = 'H5'

  type = 'header5'
}

export default Header5ButtonMenu
