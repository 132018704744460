/**
 * @description i18n zh-CN
 * @author wangfupeng
 */

export default {
  listModule: {
    unOrderedList: '无序列表',
    orderedList: '有序列表',
  },
}
