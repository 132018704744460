/**
 * @description i18n zh-CN
 * @author wangfupeng
 */

export default {
  highLightModule: {
    selectLang: '选择语言',
  },
}
