/**
 * @description i18n en
 * @author wangfupeng
 */

export default {
  uploadImgModule: {
    uploadImage: 'Upload Image',
    uploadError: '{{fileName}} upload error',
  },
}
