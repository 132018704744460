/**
 * @description i18n en
 * @author wangfupeng
 */

export default {
  listModule: {
    unOrderedList: 'Unordered list',
    orderedList: 'Ordered list',
  },
}
